<template>
  <section class="app-ecommerce-details">
    <!-- Alert: No item found -->

    <b-alert variant="danger" :show="inventory === undefined">
      <h4 class="alert-heading">
        Error fetching inventory data
      </h4>
      <div class="alert-body">
        No item found with this item id. Check
        <b-link class="alert-link" :to="{ name: 'inventory-list' }">
          Inventory
        </b-link>
        for other items.
      </div>
    </b-alert>
    <b-overlay :show="formShow" rounded="sm" no-fade>
      <b-row>
        <b-col cols="12" xl="10" md="9">
          <!-- Vehiclle Image and Name -->
          <b-card v-if="inventory" no-body>
            <b-card-body>
              <b-row class="my-2">
                <!-- Left: inventory Image Container -->

                <b-col cols="12" md="5" class="d-flex align-items-center justify-content-center mb-2 mb-md-0">
                  <!--  <img v-for="(image, i) in images" :key="i" :src="image" @click="index = i" class="mr-5" />
                <vue-gallery-slideshow :images="images" :index="index" @close="index = null"></vue-gallery-slideshow> -->

                  <div v-if="images.length > 0">
                    <b-img thumbnail :src="images[0]" @click="index = 0" class="inventory-img d-block" fluid />

                    <b-row class="mt-1" style="text-align: -webkit-center;">
                      <b-col cols="3">
                        <b-img :src="images[1]" v-if="images[1] != null" thumbnail style="height:100%" width="100%" @click="index = 1" class="float-left" fluid />
                      </b-col>
                      <b-col cols="3">
                        <b-img :src="images[2]" v-if="images[2] != null" thumbnail style="height:100%" width="100%" @click="index = 2" fluid />
                      </b-col>
                      <b-col cols="3">
                        <b-img :src="images[3]" v-if="images[3] != null" thumbnail style="height:100%" width="100%" @click="index = 3" fluid />
                      </b-col>
                      <b-col cols="3">
                        <b-img :src="images[4]" v-if="images[4] != null" thumbnail style="height:100%" width="100%" @click="index = 4" class="float-right" fluid />
                      </b-col>
                    </b-row>

                    <vue-gallery-slideshow :images="images" :index="index" @close="index = null" class="pt-5"></vue-gallery-slideshow>
                  </div>

                  <div v-if="images.length == 0" class="d-flex align-items-center justify-content-center">
                    <b-img src="@/assets/images/no-car.png" class="inventory-img" fluid />
                  </div>
                </b-col>

                <!-- Right: inventory Details -->
                <b-col cols="12" md="5">
                  <div class="d-flex">
                    <h1 class="bd-highligh text-dark">
                      <strong
                        >{{ inventory.get_model_year ? inventory.get_model_year.value : '' }}
                        -
                        {{ inventory.get_make ? inventory.get_make.value : '' }}
                        -
                        {{ inventory.get_model ? inventory.get_model.value : '' }}</strong
                      >
                      <small>{{ inventory.modelVariation ? inventory.modelVariation : '' }}</small>
                    </h1>

                    <!-- <b-badge variant="success" class="ml-auto p-1 bd-highlight" v-if="inventory.condition == 'new'">
                  New
                  <  /b-badge>

                 <b-badge variant="dark" class="ml-auto p-1 bd-highlight" v-if="inventory.condition != 'new'">
                 Used
                 </b-badge> -->
                  </div>

                  <div class="ecommerce-details-price d-flex flex-wrap mt-1">
                    <h1 class="item-price mr-1">
                      <!-- <money-format :value="inventory.listingPrice" :subunits-value="false" :hide-subunits="false"> </money-format> -->
                      {{ formatPrice(inventory.listingPrice) }}
                    </h1>
                  </div>

                  <div class="ecommerce-details-price d-flex flex-wrap mt-1">
                    <h5 class="item-price mr-1">Condition:</h5>
                    {{ inventory.condition }}
                  </div>

                  <div class="ecommerce-details-price d-flex flex-wrap mt-1">
                    <h5 class="item-price mr-1">Stock Number:</h5>
                    {{ inventory.stockNumber }}
                  </div>

                  <div class="ecommerce-details-price d-flex flex-wrap mt-1">
                    <h5 class="item-price mr-1">VIN Number:</h5>
                    {{ inventory.vinNo }}
                  </div>

                  <div class="ecommerce-details-price d-flex flex-wrap mt-1">
                    <h5 class="item-price mr-1">Mileage Unit:</h5>
                    {{ inventory.mileageUnit }}
                  </div>

                  <div class="ecommerce-details-price d-flex flex-wrap mt-1">
                    <h5 class="item-price mr-1">Mileage:</h5>
                    {{ inventory.mileageValue }}
                  </div>

                  <div class="ecommerce-details-price d-flex flex-wrap mt-1">
                    <h5 class="item-price mr-1">Lot Location:</h5>
                    {{ inventory.location }}
                  </div>

                  <div class="ecommerce-details-price d-flex flex-wrap mt-1">
                    <h5 class="item-price mr-1">Publish Status:</h5>
                    <span v-if="inventory.publish == 'yes'">Sent to published vehicles list</span>
                    <span v-else>Not Published</span>
                  </div>

                  <!-- <div class="ecommerce-details-price d-flex flex-wrap mt-1">
              <span class="card-text mr-1 text-justify"><strong>Description : </strong>{{ inventory.description }}</span>
            </div> -->
                </b-col>
              </b-row>
            </b-card-body>
          </b-card>

          <!-- description -->
          <b-card v-if="!!inventory" no-body class="card-statistics">
            <b-card-body class="statistics-body">
              <b-row>
                <b-col cols="12" md="12" xl="12">
                  <h1 style="margin-bottom: 1rem">Description</h1>
                  <p>{{ inventory.description }}</p>
                </b-col>
              </b-row>
            </b-card-body>
          </b-card>

          <!-- Specification -->
          <b-card v-if="!!inventory" no-body class="card-statistics">
            <b-card-header>
              <b-card-title><h1>Specification</h1> </b-card-title>
            </b-card-header>
            <b-row style="padding-left: 1.5rem">
              <b-col cols="12" md="12" xl="12">
                <app-collapse accordion>
                  <app-collapse-item title="General Information">
                    <table>
                      <caption></caption>
                      <tbody>
                        <tr>
                          <th>Stock Number:</th>
                          <td>
                            <span> {{ inventory.stockNumber ? inventory.stockNumber : 'N/A' }}</span>
                          </td>
                        </tr>
                        <tr>
                          <th>Model Year:</th>
                          <td>
                            <span>
                              {{ inventory.get_model_year ? inventory.get_model_year.value : 'N/A' }}
                            </span>
                          </td>
                        </tr>
                        <tr>
                          <th>Make:</th>
                          <td>
                            <span>
                              {{ inventory.get_make ? inventory.get_make.value : 'N/A' }}
                            </span>
                          </td>
                        </tr>
                        <tr>
                          <th>Model:</th>
                          <td>
                            <span>
                              {{ inventory.get_model ? inventory.get_model.value : 'N/A' }}
                            </span>
                          </td>
                        </tr>
                        <tr>
                          <th>Model Variation:</th>
                          <td>
                            <span>
                              {{ inventory.modelVariation ? inventory.modelVariation : 'N/A' }}
                            </span>
                          </td>
                        </tr>
                        <tr>
                          <th>Mileage:</th>
                          <td>
                            <span>
                              {{ inventory.mileageValue ? inventory.mileageValue : 'N/A' }}
                            </span>
                          </td>
                        </tr>
                        <tr>
                          <th>Mileage Unit:</th>
                          <td>
                            <span>
                              {{ inventory.mileageUnit ? inventory.mileageUnit : 'N/A' }}
                            </span>
                          </td>
                        </tr>

                        <tr>
                          <th>Body Type:</th>
                          <td>
                            <span> {{ inventory.get_body ? inventory.get_body.value : 'N/A' }}</span>
                          </td>
                        </tr>
                        <tr>
                          <th>Interior Trim:</th>
                          <td>
                            <span>
                              {{ inventory.interiorTrim ? inventory.interiorTrim : 'N/A' }}
                            </span>
                          </td>
                        </tr>
                        <tr>
                          <th>Chassis Code:</th>
                          <td>
                            <span>
                              {{ inventory.chassisCode ? inventory.chassisCode : 'N/A' }}
                            </span>
                          </td>
                        </tr>
                        <tr>
                          <th>Doors:</th>
                          <td>
                            <span>
                              {{ inventory.seatingCapacity ? inventory.seatingCapacity : 'N/A' }}
                            </span>
                          </td>
                        </tr>
                        <tr>
                          <th>Seats:</th>
                          <td>
                            <span>
                              {{ inventory.numberOfDoors ? inventory.numberOfDoors : 'N/A' }}
                            </span>
                          </td>
                        </tr>
                        <tr>
                          <th>External Colour</th>
                          <td>
                            <span>
                              {{ inventory.get_ext_colour ? inventory.get_ext_colour.value : 'N/A' }}
                            </span>
                          </td>
                        </tr>
                        <tr>
                          <th>Internal Colour:</th>
                          <td>
                            <span>
                              {{ inventory.get_int_colour ? inventory.get_int_colour.value : 'N/A' }}
                            </span>
                          </td>
                        </tr>
                        <tr>
                          <th>Listing Price:</th>
                          <td>
                            <span>
                              {{ inventory.listingPrice ? inventory.listingPrice : 'N/A' }}
                            </span>
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </app-collapse-item>
                  <app-collapse-item title="Engine Technical Data">
                    <table>
                      <caption></caption>
                      <tbody>
                        <tr>
                          <th>Engine Code:</th>
                          <td>
                            <span> {{ inventory.engineCode ? inventory.engineCode : 'N/A' }}</span>
                          </td>
                        </tr>
                        <tr>
                          <th>Number of Cylinders :</th>
                          <td>
                            <span>
                              {{ inventory.numberOfCylinders ? inventory.numberOfCylinders : 'N/A' }}
                            </span>
                          </td>
                        </tr>
                        <tr>
                          <th>Engine Alignment :</th>
                          <td>
                            <span>
                              {{ inventory.engineAlignment ? inventory.engineAlignment : 'N/A' }}
                            </span>
                          </td>
                        </tr>
                        <tr>
                          <th>Engine Position :</th>
                          <td>
                            <span> {{ inventory.enginePosition ? inventory.enginePosition : 'N/A' }}</span>
                          </td>
                        </tr>
                        <tr>
                          <th>Engine Displacement :</th>
                          <td>
                            <span>
                              {{ inventory.engineDisplacement ? inventory.engineDisplacement : 'N/A' }}
                            </span>
                          </td>
                        </tr>
                        <tr>
                          <th>Fuel Type :</th>
                          <td>
                            <span>
                              {{ inventory.get_fuel ? inventory.get_fuel.value : 'N/A' }}
                            </span>
                          </td>
                        </tr>
                        <tr>
                          <th>Fuel Tank Capacity:</th>
                          <td>
                            <span>
                              {{ inventory.fuelTankCapacity ? inventory.fuelTankCapacity : 'N/A' }}
                            </span>
                          </td>
                        </tr>
                        <tr>
                          <th>Fuel System:</th>
                          <td>
                            <span>
                              {{ inventory.fuelSystem ? inventory.fuelSystem : 'N/A' }}
                            </span>
                          </td>
                        </tr>
                        <tr>
                          <th>Bore x Stroke:</th>
                          <td>
                            <span> {{ inventory.boreStroke ? inventory.boreStroke : 'N/A' }}</span>
                          </td>
                        </tr>
                        <tr>
                          <th>Number of Valves:</th>
                          <td>
                            <span>
                              {{ inventory.numberOfValves ? inventory.numberOfValves : 'N/A' }}
                            </span>
                          </td>
                        </tr>
                        <tr>
                          <th>Aspiration:</th>
                          <td>
                            <span>
                              {{ inventory.aspiration ? inventory.aspiration : '' }}
                            </span>
                          </td>
                        </tr>
                        <tr>
                          <th>Compression Ratio:</th>
                          <td>
                            <span>
                              {{ inventory.compressionRatio ? inventory.compressionRatio : 'N/A' }}
                            </span>
                          </td>
                        </tr>
                        <tr>
                          <th>Maximum Horsepower :</th>
                          <td>
                            <span>
                              {{ inventory.maximumHorsepower ? inventory.maximumHorsepower : 'N/A' }}
                            </span>
                          </td>
                        </tr>
                        <tr>
                          <th>Maximum Torque :</th>
                          <td>
                            <span>
                              {{ inventory.maximumTorque ? inventory.maximumTorque : 'N/A' }}
                            </span>
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </app-collapse-item>
                  <app-collapse-item title="Drivetrain, brakes and suspension">
                    <table>
                      <caption></caption>
                      <tbody>
                        <tr>
                          <th>Drivetrain</th>
                          <td>
                            <span> {{ inventory.get_drive_train ? inventory.get_drive_train.value : 'N/A' }}</span>
                          </td>
                        </tr>
                        <tr>
                          <th>Transmission :</th>
                          <td>
                            <span>
                              {{ inventory.get_transmmission ? inventory.get_transmmission.value : 'N/A' }}
                            </span>
                          </td>
                        </tr>
                        <tr>
                          <th>Front Suspension:</th>
                          <td>
                            <span>
                              {{ inventory.frontSuspension ? inventory.frontSuspension : 'N/A' }}
                            </span>
                          </td>
                        </tr>
                        <tr>
                          <th>Rear Suspension :</th>
                          <td>
                            <span> {{ inventory.rearSuspension ? inventory.rearSuspension : 'N/A' }}</span>
                          </td>
                        </tr>
                        <tr>
                          <th>Chassis Type :</th>
                          <td>
                            <span>
                              {{ inventory.chassisType ? inventory.chassisType : 'N/A' }}
                            </span>
                          </td>
                        </tr>
                        <tr>
                          <th>Chassis Materials :</th>
                          <td>
                            <span>
                              {{ inventory.chassisMaterials ? inventory.chassisMaterials : 'N/A' }}
                            </span>
                          </td>
                        </tr>
                        <tr>
                          <th>Front Brakes - Disc Dimensions:</th>
                          <td>
                            <span>
                              {{ inventory.frontBrakes ? inventory.frontBrakes : 'N/A' }}
                            </span>
                          </td>
                        </tr>
                        <tr>
                          <th>Rear Brakes - Disc Dimensions:</th>
                          <td>
                            <span>
                              {{ inventory.rearBrakes ? inventory.rearBrakes : 'N/A' }}
                            </span>
                          </td>
                        </tr>
                        <tr>
                          <th>Front Tyres - Rims Dimensions:</th>
                          <td>
                            <span> {{ inventory.frontTyres ? inventory.frontTyres : 'N/A' }}</span>
                          </td>
                        </tr>
                        <tr>
                          <th>Rear Tyres - Rims Dimensions s:</th>
                          <td>
                            <span>
                              {{ inventory.rearTyres ? inventory.rearTyres : 'N/A' }}
                            </span>
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </app-collapse-item>
                  <app-collapse-item title="Size and Dimensions">
                    <table>
                      <caption></caption>
                      <tbody>
                        <tr>
                          <th>Length :</th>
                          <td>
                            <span> {{ inventory.length ? inventory.length : 'N/A' }}</span>
                          </td>
                        </tr>
                        <tr>
                          <th>Width :</th>
                          <td>
                            <span>
                              {{ inventory.width ? inventory.width : 'N/A' }}
                            </span>
                          </td>
                        </tr>
                        <tr>
                          <th>Height :</th>
                          <td>
                            <span>
                              {{ inventory.height ? inventory.height : 'N/A' }}
                            </span>
                          </td>
                        </tr>
                        <tr>
                          <th>Wheelbase :</th>
                          <td>
                            <span> {{ inventory.wheelbase ? inventory.wheelbase : 'N/A' }}</span>
                          </td>
                        </tr>
                        <tr>
                          <th>Front Axle Width :</th>
                          <td>
                            <span>
                              {{ inventory.frontAxleWidth ? inventory.frontAxleWidth : 'N/A' }}
                            </span>
                          </td>
                        </tr>
                        <tr>
                          <th>Rear Axle Width :</th>
                          <td>
                            <span>
                              {{ inventory.rearAxleWidth ? inventory.rearAxleWidth : 'N/A' }}
                            </span>
                          </td>
                        </tr>
                        <tr>
                          <th>Curb Weight :</th>
                          <td>
                            <span>
                              {{ inventory.curbWeight ? inventory.curbWeight : 'N/A' }}
                            </span>
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </app-collapse-item>
                </app-collapse>
              </b-col>
            </b-row>
          </b-card>

          <b-row class="match-height" v-if="!!inventory">
            <!-- Vehiclle Msrp Options -->
            <b-col sm="12" md="6" v-if="inventory.get_msrp_options.length > 0">
              <b-card class="card-transaction" no-body>
                <b-card-header>
                  <b-card-title>Msrp Options</b-card-title>
                </b-card-header>

                <b-card-body>
                  <div v-for="msrp in inventory.get_msrp_options" :key="msrp.id" class="transaction-item border-bottom border-bottom-light pb-1">
                    <b-media no-body>
                      <b-media-aside>
                        <b-avatar rounded size="32" variant="light-primary">
                          <feather-icon size="16" icon="CpuIcon" />
                        </b-avatar>
                      </b-media-aside>
                      <b-media-body class="align-self-center">
                        <h6 class="transaction-title">
                          {{ msrp.title }}
                        </h6>
                      </b-media-body>
                    </b-media>

                    <div class="font-weight-bolder text-success">
                      <money-format :value="msrp.price" :subunits-value="false" :hide-subunits="false"> </money-format>
                    </div>
                  </div>
                </b-card-body>
              </b-card>
            </b-col>

            <!-- Vehiclle Msrp Options -->
            <b-col sm="12" md="6" v-if="inventory.get_accessiore_options.length > 0">
              <b-card class="card-transaction" no-body>
                <b-card-header>
                  <b-card-title>Accessiore Options</b-card-title>
                </b-card-header>

                <b-card-body>
                  <div v-for="accessiore in inventory.get_accessiore_options" :key="accessiore.id" class="transaction-item border-bottom border-bottom-light pb-1">
                    <b-media no-body>
                      <b-media-aside>
                        <b-avatar rounded size="32" variant="light-info">
                          <feather-icon size="16" icon="ShoppingBagIcon" />
                        </b-avatar>
                      </b-media-aside>
                      <b-media-body class="align-self-center">
                        <h6 class="transaction-title">
                          {{ accessiore.title }}
                        </h6>
                      </b-media-body>
                    </b-media>

                    <div class="font-weight-bolder text-success">
                      <h4>{{ accessiore.quantity }}</h4>
                    </div>
                  </div>
                </b-card-body>
              </b-card>
            </b-col>
          </b-row>
        </b-col>

        <b-col cols="12" xl="2" md="3" v-if="inventory">
       
          <b-card>
            <b-button v-ripple.400="'rgba(255, 255, 255, 0.15)'" v-b-toggle.sidebar-send-purchase @click="addNewFile()" size="sm" block variant="primary">
              Add New File
            </b-button>

            <!-- user suggestion  -->
            <div v-for="(data, index) in files" :key="index" :class="index == 0 ? 'mt-2' : 'mt-1'">
              <div v-if="data.status == 'A'" class="d-flex justify-content-between align-items-center">
                <b-img src="@/assets/images/file.png" @click="fileEdit(data)" class="mr-50 " style="cursor:pointer" width="25px" />
                <div class="user-page-info pr-1" style="width: 100%">
                  <small @click="fileEdit(data)" style="cursor:pointer; font-size:.8em">{{ data.fileName }} </small>
                </div>
                <b-button v-ripple.400="'rgba(186, 191, 199, 0.15)'" variant="primary" target="_blank" :href="fileURL + data.fileLink" style="cursor:alias; padding: 1% 1%; margin-right:2px" class="btn-icon ml-auto" size="sm">
                  <feather-icon icon="DownloadIcon" />
                </b-button>

                <b-button v-ripple.400="'rgba(186, 191, 199, 0.15)'" variant="danger" @click="deleteFile(data)" v-if="userRole != 'supermanager'" class="btn-icon ml-auto" style="padding: 1% 1%;" size="sm">
                  <feather-icon icon="XIcon" />
                </b-button>

                <b-button v-ripple.400="'rgba(186, 191, 199, 0.15)'" variant="danger" @click="deleteFile(data)" v-else class="btn-icon ml-auto" style="padding: 1% 1%;" size="sm">
                  <feather-icon icon="XIcon" />
                </b-button>
              </div>
              <div v-else class="d-flex justify-content-between align-items-center">
                <b-img src="@/assets/images/linkIcon.png" @click="linkEdit(data)" class="mr-50 " style="cursor:pointer" width="20px" />
                <div class="user-page-info pr-1" style="width: 100%">
                  <small @click="linkEdit(data)" style="cursor:pointer; font-size:.8em">{{ data.fileName }} </small>
                </div>
                <b-button v-ripple.400="'rgba(186, 191, 199, 0.15)'" variant="primary" target="_blank" :href="data.fileLink" style="cursor:alias; padding: 1% 1%; margin-right:2px" class="btn-icon ml-auto" size="sm" v-b-tooltip.hover title="Go to url adress">
                  <feather-icon icon="ExternalLinkIcon" />
                </b-button>

                <b-button v-ripple.400="'rgba(186, 191, 199, 0.15)'" variant="danger" @click="deleteFile(data)" v-if="userRole != 'supermanager'" class="btn-icon ml-auto" style="padding: 1% 1%;" size="sm">
                  <feather-icon icon="XIcon" />
                </b-button>

                <b-button v-ripple.400="'rgba(186, 191, 199, 0.15)'" variant="danger" @click="deleteFile(data)" v-else class="btn-icon ml-auto" style="padding: 1% 1%;" size="sm">
                  <feather-icon icon="XIcon" />
                </b-button>
              </div>
            </div>

            <div v-if="files.length == 0" class="d-flex justify-content-center align-items-center mt-1 mb-1">
              No Files
            </div>
          </b-card>

          <b-card v-if="inventory.get_timelines.length > 0" style="height: 50%; overflow-y:scroll; ">
            <app-timeline class="mt-2">
              <app-timeline-item v-for="item in inventory.get_timelines" :key="item.id" :title="item.title" :subtitle="'By ' + item.username" :icon="varicon(item.status)" :time="dateFormat(item.created_at)" :variant="varyant(item.status)" />
            </app-timeline>
          </b-card>
        </b-col>
      </b-row>
      <b-modal id="modal-file" cancel-variant="outline-secondary" ok-title="Save File" @ok="handleOk" @show="resetModal" @hidden="resetModal" cancel-title="Close" :no-close-on-backdrop="true" centered title="Add New File">
        <b-form ref="fileRules">
          <b-form-group :state="nameState" invalid-feedback="File Name is required">
            <label for="fileName">File Name:</label>
            <b-form-input id="fileName" type="text" :state="nameState" v-model="modalFileName" required placeholder="File Name" />
          </b-form-group>
          <b-form-group>
            <b-form-checkbox class="custom-control-primary" name="check-button" switch inline v-model="selectFileType">
              <span class="switch-icon-left">
                <feather-icon icon="FileIcon" />
              </span>
              <span class="switch-icon-right">
                <feather-icon icon="LinkIcon" />
              </span>
            </b-form-checkbox>
          </b-form-group>
          <b-form-group :state="fileState" invalid-feedback="File is required" v-if="selectFileType">
            <b-form-file @input="fileSelected" type="file" :state="fileState" required placeholder="Choose a file or drop it here..." drop-placeholder="Drop file here..." class="animate__animated animate__fadeIn" />
          </b-form-group>
          <b-form-group :state="linkState" invalid-feedback="Link is required" v-else>
            <b-form-input :state="linkState" id="basicInput" v-model="fileLink" required placeholder="Enter Link" class="animate__animated animate__fadeIn" />
          </b-form-group>
        </b-form>
        <b-col v-if="percentCompleted != 0 && percentCompleted != 100" class="mt-2">
          <b-progress key="info" animated :value="percentCompleted" show-progress variant="dark" class="'progress-bar-info'" />
        </b-col>
      </b-modal>

      <b-modal id="modal-file-update" cancel-variant="outline-secondary" ok-title="Change File" @ok="handleUpdate" cancel-title="Close" :no-close-on-backdrop="true" centered title="Add New File">
        <b-form ref="fileRules">
          <b-form-group :state="nameState" invalid-feedback="File Name is required">
            <label for="fileName">File Name:</label>
            <b-form-input id="fileName" type="text" :state="nameState" v-model="modalFileName" required placeholder="File Name" />
          </b-form-group>
        </b-form>
        <b-col v-if="percentCompleted != 0 && percentCompleted != 100" class="mt-2">
          <b-progress key="info" animated :value="percentCompleted" show-progress variant="dark" class="'progress-bar-info'" />
        </b-col>
      </b-modal>

  




  
    </b-overlay>
  </section>
</template>

<script>
import { useRouter } from '@core/utils/utils';
import store from '@/store';
import { onUnmounted, watch, ref } from '@vue/composition-api';
import router from '@/router';
import { BModal, VBModal, BMediaBody, BMediaAside, BAvatar, BCarousel, BCarouselSlide, BCard, BCardBody, BRow, BCol, BImg, BCardText, BLink, BButton, BDropdown, BDropdownItem, BAlert, BBadge, BCardHeader, BCardTitle, BMedia, VBToggle } from 'bootstrap-vue';
import Ripple from 'vue-ripple-directive';
import testleaseStoreModule from './testleaseStoreModule';
import VueGallerySlideshow from 'vue-gallery-slideshow';
import { Swiper, SwiperSlide } from 'vue-awesome-swiper';
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue';
import MoneyFormat from 'vue-money-format';
import axiosIns from '@/libs/axios';
import moment from 'moment';
import AppTimeline from '@core/components/app-timeline/AppTimeline.vue';
import AppTimelineItem from '@core/components/app-timeline/AppTimelineItem.vue';

import AppCollapse from '@core/components/app-collapse/AppCollapse.vue';
import AppCollapseItem from '@core/components/app-collapse/AppCollapseItem.vue';
import flatPickr from 'vue-flatpickr-component';
import vSelect from 'vue-select';
import _ from 'lodash';
import 'animate.css';

export default {
  directives: {
    Ripple,
    'b-toggle': VBToggle,
  },

  components: {
    // BSV
    vSelect,
    BBadge,
    BCarousel,
    BCarouselSlide,
    BCard,
    BCardBody,
    BRow,
    BCol,
    BImg,
    BCardText,
    BLink,
    BButton,
    BDropdown,
    BDropdownItem,
    BAlert,
    VueGallerySlideshow,
    MoneyFormat,
    BCardHeader,
    BCardTitle,
    BMedia,
    BMediaAside,
    BAvatar,
    BMediaBody,
    BModal,
    AppTimeline,
    AppTimelineItem,

    AppCollapse,
    AppCollapseItem,
    flatPickr,
    // 3rd Party
    Swiper,
    SwiperSlide,
  },

  directives: {
    'b-modal': VBModal,
    Ripple,
  },

  setup() {
    const LEASE_APP_STORE_MODULE_NAME = 'testlease';

    // Register module
    if (!store.hasModule(LEASE_APP_STORE_MODULE_NAME)) store.registerModule(LEASE_APP_STORE_MODULE_NAME, testleaseStoreModule);

    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule(LEASE_APP_STORE_MODULE_NAME)) store.unregisterModule(LEASE_APP_STORE_MODULE_NAME);
    });
  },

  created() {
    this.fetchInventory();
  },

  data() {
    var inventory = null;
    var images = [];

    return {
      // Fetched inventory
      userRole: JSON.parse(localStorage.getItem('userData')).role,
      inventory,
      baseURL: store.state.app.baseURL,
      modalLocation: null,
      modalMileageValue: null,

      modalClosingDate: null,
      modalClosingUser: null,
      images,
      index: null,
      formShow: false,
      dropUsers: [],
      requiredInputs: {},

      //FILES
      where: router.currentRoute.name,
      files: [],
      selectFileType: true,
      fileLink: null,
      modalId: null,
      modalFileName: null,
      modalFile: null,
      download: false,
      fileURL: store.state.app.fileURL,
      percentCompleted: 0,
      // UI
    };
  },
  computed: {
    nameState() {
      return this.modalFileName != null && this.modalFileName != '' ? true : false;
    },

    fileState() {
      return this.modalFile != null && this.modalFile != '' ? true : false;
    },
    linkState() {
      let expression = /[-a-zA-Z0-9@:%._\+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b([-a-zA-Z0-9()@:%_\+.~#?&//=]*)?/gi;
      let regex = new RegExp(expression);
      let link = this.fileLink;
      if (link != null) {
        if (link.match(regex)) {
          return true;
        } else {
          return false;
        }
      } else {
        return false;
      }
    },

    modalMileageState() {
      return this.modalMileageValue != null && this.modalMileageValue != '' ? true : false;
    },

    modalLocationState() {
      return this.modalLocation != null && this.modalLocation != '' ? true : false;
    },

    modalDateState() {
      return this.modalClosingDate != null && this.modalClosingDate != '' ? true : false;
    },
    modalUserState() {
      return this.modalClosingUser != null && this.modalClosingUser != '' ? true : false;
    },
  },

  methods: {
 


    fetchInventory() {
      store
        .dispatch('testlease/fetchInventoryView', {
          id: router.currentRoute.params.id,
        })
        .then((response) => {
          this.inventory = response.data.inventory;
          this.modalLocation = response.data.inventory.location;
          this.modalMileageValue = response.data.inventory.mileageValue;

          this.inventory.get_timelines = this.inventory.get_timelines.reverse();

          response.data.users.forEach((element) => {
            this.dropUsers.push({
              name: element.name + ' ' + element.surname,
              id: element.id,
            });
          });

          this.inventory.get_images.forEach((element) => {
            this.images.push(store.state.app.baseURL + '/' + element.name);
          });
          this.files = response.data.inventory.get_files;
        })
        .catch((error) => {
          if (error.response.status === 500) {
            this.inventory = undefined;
          }
        });
    },

   
 
    formatPrice(value, val) {
      if (value != null) {
        let val = (value / 1).toFixed(2).replace(',', '.');
        return val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
      }
    },
   

    fileEdit(data) {
      this.modalId = data.id;
      if (this.userRole == 'supermanager') {
        this.modalFileName = data.fileName;

        this.$bvModal.show('modal-file-update');
      } else if (this.inventory.status != 'PE') {
        this.modalFileName = data.fileName;
        this.$bvModal.show('modal-file-update');
      }
    },

    linkEdit(data) {
      this.modalId = data.id;
      if (this.userRole == 'supermanager') {
        this.modalFileName = data.fileName;
        this.$bvModal.show('modal-file-update');
      } else if (this.inventory.status != 'PE') {
        this.modalFileName = data.fileName;
        this.$bvModal.show('modal-file-update');
      }
    },

    deleteFile(data) {
      this.$swal({
        title: 'Are you sure?',
        text: '',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: 'Yes, delete it!',
        customClass: {
          confirmButton: 'btn btn-primary',
          cancelButton: 'btn btn-danger ml-1',
        },
        buttonsStyling: false,
      }).then((result) => {
        if (result.value) {
          this.formShow = true;

          axiosIns
            .delete(`deleteLeaseInventoryFile/${data.id}`)
            .then((res) => {
              var tempList = [];
              this.files.forEach((element) => {
                if (element.id != data.id) {
                  tempList.push(element);
                }
              });

              this.files = tempList;
              this.formShow = false;
            })
            .catch((error) => {
              console.log('error delete ', error);
            });
        }
      });
    },

    addNewFile() {
      this.$bvModal.show('modal-file');
    },

    handleOk(bvModalEvent) {
      // Prevent modal from closing
      bvModalEvent.preventDefault();

      if (this.selectFileType) {
        if (this.percentCompleted == 100) {
          var data = {
            vehicleId: this.inventory.id,
            fileName: this.modalFileName,
            fileLink: this.modalFile,
            status: 'A',
          };

          // Trigger submit handler
          this.modalOkFile(data);
        }
      } else {
        var data = {
          vehicleId: this.inventory.id,
          fileName: this.modalFileName,
          fileLink: this.fileLink,
          status: 'B',
        };
        this.modalOkFile(data);
      }
    },

    handleUpdate(bvModalEvent) {
      // Prevent modal from closing
      bvModalEvent.preventDefault();
      // Trigger submit handler
      this.modalUpdate();
    },

    resetModal() {
      this.modalFileName = null;
      this.modalFile = null;
    },

    modalOkFile(data) {
      if (!this.checkFormValidity()) {
        return;
      }

      this.$nextTick(() => {
        this.$bvModal.hide('modal-file');
      });

      this.formShow = true;
      axiosIns
        .post('saveLeaseInventoryFile', data)
        .then((response) => {
          this.files.push({
            id: response.data.id,
            fileName: response.data.fileName,
            fileLink: response.data.fileLink,
            status: response.data.status,
          });

          this.selectFileType = true;
          this.fileLink = null;
          this.modalFileName = null;
          this.modalFile = null;

          this.formShow = false;
        })
        .catch((error) => {
          this.formShow = false;
          console.log(error);
        });
    },

    modalUpdate(val) {
      if (!this.checkFormValidity()) {
        return;
      }

      this.$nextTick(() => {
        this.$bvModal.hide('modal-file-update');
      });

      this.formShow = true;

      var data = {
        id: this.modalId,
        fileName: this.modalFileName,
        fileLink: this.modalFile,
      };

      axiosIns
        .put('updateLeaseInventoryFile', data)
        .then((response) => {
          this.modalFileName = null;
          this.modalFile = null;

          this.files.forEach((file) => {
            if (file.id == this.modalId) {
              file.fileName = response.data.fileName;
            }
          });

          this.formShow = false;
        })
        .catch((error) => {
          this.formShow = false;
          console.log(error);
        });
    },

    fileSelected(event) {
      //  this.formShow = true;
      let formData = new FormData();
      formData.append('file', event);

      const config = {
        onUploadProgress: function(progressEvent) {
          this.percentCompleted = Math.round((progressEvent.loaded * 100) / progressEvent.total);
        }.bind(this),
      };

      axiosIns
        .post('uploadLeaseInventoryFile', formData, config)
        .then((response) => {
          this.modalFile = response.data;

          // this.tempImage = null;
          // this.selectedImage = null;
          this.formShow = false;
        })
        .catch((error) => {
          this.formShow = false;
          console.log(error);
        });
    },

    checkFormValidity() {
      const nameValid = this.$refs.fileRules.checkValidity();
      const fileValid = this.$refs.fileRules.checkValidity();

      return fileValid && nameValid;
    },

    checkMlieageLocationValidity() {
      return this.modalLocation != null && this.modalMileageValue != null && this.modalLocation != '' && this.modalMileageValue != '';
    },

    checkClosingValidity() {
      return this.modalClosingUser != null && this.modalClosingDate != null && this.modalClosingUser != '' && this.modalClosingDate != '';
    },

    varyant(val) {
      if (val == 'A') {
        return 'success';
      } else if (val == 'B') {
        return 'info';
      } else if (val == 'C') {
        return 'success';
      } else if (val == 'D') {
        return '';
      } else if (val == 'E') {
        return 'warning';
      } else if (val == 'I') {
        return 'success';
      } else if (val == 'J') {
        return 'danger';
      } else if (val == 'F') {
        return 'info';
      } else if (val == 'P') {
        return 'danger';
      } else if (val == 'G') {
        return 'success';
      } else if (val == 'H') {
        return 'danger';
      } else {
        return 'success';
      }
    },

    varicon(val) {
      if (val == 'A') {
        return 'AwardIcon';
      } else if (val == 'B') {
        return 'InfoIcon';
      } else if (val == 'C') {
        return 'ClockIcon';
      } else if (val == 'D') {
        return 'InfoIcon';
      } else if (val == 'E' || val == 'I' || val == 'J') {
        return 'UserIcon';
      } else if (val == 'F') {
        return 'GridIcon';
      } else if (val == 'P') {
        return 'GridIcon';
      } else if (val == 'G') {
        return 'DollarSignIcon';
      } else if (val == 'H') {
        return 'EyeOffIcon';
      } else {
        return 'success';
      }
    },

    dateFormat(val) {
      return moment(String(val)).format('DD-MM-YYYY HH:mm');
    },

    pdfDownload(val) {
      this.download = true;

      store.dispatch('inventory/inventoryClosingSheet', val).then((response) => {
        if (response.status == 200) {
          const url = window.URL.createObjectURL(new Blob([response.data]));
          const link = document.createElement('a');
          link.href = url;

          link.setAttribute('download', 'Closing Sheet' + Number(val) + '.pdf');

          document.body.appendChild(link);
          link.click();

          this.$toast({
            component: ToastificationContent,
            props: {
              title: 'Successful',
              text: '✔️ Closing pdf is created!',
              icon: 'ThumbsUpIcon',
              variant: 'success',
            },
          });
          this.download = false;
        }
      });
    },
   
  },
};
</script>

<style lang="scss">
@import '~@core/scss/base/pages/app-ecommerce-details.scss';
@import '@core/scss/vue/libs/swiper.scss';
@import '~swiper/css/swiper.css';
@import '@core/scss/vue/libs/vue-select.scss';
@import '@core/scss/vue/libs/vue-flatpicker.scss';

.icon {
  stroke: black;
  fill: none;
  width: 36px;
  height: 36px;
  stroke-width: 0.6666666666666666;
}
</style>
